import React from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { ButtonCus, TextCus } from "components";
import { warning } from "assets";
import { CloseOutlined } from "@mui/icons-material";

interface IModalFail {
	open: boolean;
	onClose: () => void;
}

const ModalProcessFail = ({ open, onClose }: IModalFail) => {
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					position={"relative"}
					justifyContent={"center"}
					gap={"20px"}
					padding={"20px 20px"}
					flexDirection={"column"}
					height={"300px"}
					sx={{
						width: {
							xs: "90%",
							sm: "500px",
						},
					}}
					borderRadius={"10px"}
					bgcolor={mainColor.white}>
					<IconButton
						sx={{ position: "absolute", top: 0, right: 0 }}
						onClick={() => {
							window.location.reload();
							onClose();
						}}>
						<CloseOutlined />
					</IconButton>
					<img src={warning} alt="Warning" />
					<Box
						display={"flex"}
						flexDirection={"column"}
						gap={"5px"}
						justifyContent={"center"}
						alignItems={"center"}>
						<TextCus
							fontWeight={"600"}
							fontSize={fontSize.xs}
							textAlign={"center"}>
							Something went wrong...
						</TextCus>
						<TextCus fontSize={"12px"} color={"#667085"} textAlign={"center"}>
							We had some trouble loading this page. Please refresh the page to
							try again or get in touch if the problem sticks around!
						</TextCus>
					</Box>
					<ButtonCus
						sx={{ mt: "10px" }}
						onClick={() => {
							window.location.reload();
							onClose();
						}}>
						Try again
					</ButtonCus>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalProcessFail;
