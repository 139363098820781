/** @format */

import { Box } from "@mui/material";
import { banner, bn1, bn2, bn3, faceGif, fitGif, hairGif } from "assets";
import { TextCus } from "components";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mainColor } from "theme";
import BoxStart from "./BoxStart";
import { APP_ROOT } from "utils/routes";

const arrListFeature = [
	{
		image: bn1,
		img: fitGif,
		title: "Style Fit",
		desc: "Your AI Virtual Outfit Try On",
		link: APP_ROOT.STYLE_FIT,
	},
	{
		image: bn3,
		img: hairGif,
		title: "Style Hair",
		desc: "Your AI Hairstyle Changer",
		link: "/style-hair",
	},
	{
		image: bn2,
		img: faceGif,
		title: "Gen Me",
		desc: "Your Miniature AI Version",
		link: "/gen-me",
	},
];

const Banner = () => {
	return (
		<Box
			width={"100%"}
			position={"relative"}
			display={"flex"}
			borderRadius={"24px"}>
			<LazyLoadImage
				style={{ display: "block", objectFit: "contain", width: "100%" }}
				src={banner}
				alt="banner"
			/>
			<Box
				display={"flex"}
				position={"absolute"}
				width={"100%"}
				height={"50%"}
				left={"10%"}
				justifyContent={"center"}
				gap={"5px"}
				flexDirection={"column"}>
				<TextCus
					sx={{
						fontSize: {
							xs: "18px",
							lg: "35px",
						},
					}}
					fontWeight={"700"}
					color={mainColor.primary}>
					Let&apos;s Start Your AI Fashion Journey!
				</TextCus>
			</Box>
			<Box
				display={"flex"}
				width={"100%"}
				justifyContent={"center"}
				position={"absolute"}
				sx={{
					bottom: {
						xs: "-70%",
						lg: "-50%",
						xl: "-20%",
					},
				}}>
				<Box
					className="wrap-content"
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"250px"}
					gap={"25px"}>
					{arrListFeature.map((item) => {
						return <BoxStart key={item.title} item={item} />;
					})}
				</Box>
			</Box>
		</Box>
	);
};

export default Banner;
