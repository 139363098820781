import { Box } from "@mui/material";
import { useModel, useOutfitStore } from "hooks";
import TitleSection from "pages/admin/dashboard/components/TitleSection";
import React, { useCallback, useState } from "react";
import { TParams } from "types";
import ImageItem from "./ImageItem";
import { ModalUpload } from "components";
import { Icons } from "assets";
import { fontSize, mainColor } from "theme";
import { IModelType } from "types/outfit";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const YourModels = () => {
	const [open, setOpen] = useState(false);
	const { useGetYourModels, deleteModelMutation } = useModel();
	const navigate = useNavigate();
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 11,
	});
	const {
		models: dataModel,
		isLoading,
		fetchNextPage,
		isFetchingNextPage,
		refetch,
	} = useGetYourModels(paramsModels);

	console.log("isLoading", isLoading, fetchNextPage, isFetchingNextPage);

	const handleDelete = useCallback((id) => {
		deleteModelMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileModel(item.id as string);
		setModelImage(item.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	return (
		<Box className="wrap-content" width={"100%"} mt={"50px"}>
			<Box>
				<TitleSection title="Your Models" />
			</Box>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={""}
				flexWrap={"wrap"}
				rowGap={"30px"}
				mt={"20px"}>
				<Box
					width={"calc(100% / 6 - 2px)"}
					bgcolor={"#ccc"}
					component={"div"}
					onClick={() => setOpen(true)}
					mr={"2px"}
					sx={{
						aspectRatio: 3 / 4,
						cursor: "pointer",
						":hover": {
							opacity: 0.8,
							transition: "all 0.25s ease-in-out",
						},
					}}
					gap={"10px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					borderRadius={"10px"}
					flexDirection={"column"}>
					{Icons.PlusWhite()}
					<Box
						color={mainColor.white}
						fontSize={fontSize.xs}
						fontWeight={"600"}>
						Create Model
					</Box>
				</Box>
				{dataModel.map((item) => (
					<Box width={"calc(100% / 6)"} key={item.id}>
						<ImageItem
							item={item}
							selectedImage=""
							actionDelete={handleDelete}
							handleClick={handleChooseModel}
							isLoading={deleteModelMutation.isPending}
						/>
					</Box>
				))}
			</Box>
			<ModalUpload
				open={open}
				onClose={() => setOpen(false)}
				handleRefetch={refetch}
			/>
		</Box>
	);
};

export default YourModels;
