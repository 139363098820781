/** @format */

import React from "react";

import { SvgIconCus } from "components";
import { ISVGIcon } from "types";

export const Icons = {
	Start: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="15"
			viewBox="0 0 18 15"
			fill="none">
			<path
				d="M1.16651 7.33008L16.1665 7.33008M16.1665 7.33008L10.3332 1.08008M16.1665 7.33008L10.3332 13.5801"
				stroke={color ?? "white"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Api: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none">
			<path
				d="M1.78174 18.3333C3.22261 15.8425 5.91571 14.1666 9.00022 14.1666C12.0847 14.1666 14.7778 15.8425 16.2187 18.3333"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M5.66699 10.4166C5.66699 9.72627 5.10735 9.16663 4.41699 9.16663C3.72664 9.16663 3.16699 9.72627 3.16699 10.4166C3.16699 11.107 3.72664 11.6666 4.41699 11.6666C5.10735 11.6666 5.66699 11.107 5.66699 10.4166Z"
				stroke="#3E227C"
				strokeWidth="2"
			/>
			<path
				d="M14.8335 10.4166C14.8335 9.72627 14.2739 9.16663 13.5835 9.16663C12.8931 9.16663 12.3335 9.72627 12.3335 10.4166C12.3335 11.107 12.8931 11.6666 13.5835 11.6666C14.2739 11.6666 14.8335 11.107 14.8335 10.4166Z"
				stroke="#3E227C"
				strokeWidth="2"
			/>
			<path
				d="M2.125 1.66663L2.53101 4.9147C2.65612 5.91555 3.50692 6.66663 4.51556 6.66663L13.4844 6.66663C14.4931 6.66663 15.3439 5.91555 15.469 4.9147L15.875 1.66663"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Star: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none">
			<path
				d="M9.11378 1.65332C9.48042 0.909427 10.5411 0.909426 10.9077 1.65332L12.6181 5.12365C12.7638 5.41921 13.0457 5.62404 13.3718 5.67125L17.167 6.22066C17.9863 6.33926 18.3149 7.3451 17.7236 7.92454L14.9626 10.6304C14.7285 10.8599 14.6217 11.1896 14.6768 11.5128L15.328 15.3302C15.4676 16.1483 14.6074 16.7706 13.8741 16.382L10.4789 14.5832C10.1861 14.4281 9.83543 14.4281 9.5426 14.5832L6.14743 16.382C5.4141 16.7706 4.55396 16.1483 4.69351 15.3302L5.34446 11.5142C5.39973 11.1902 5.29226 10.8598 5.05699 10.6303L2.28502 7.92616C1.69153 7.34719 2.01989 6.33899 2.84051 6.22059L6.64936 5.67104C6.97566 5.62397 7.25779 5.41909 7.40354 5.12338L9.11378 1.65332Z"
				stroke="#3E227C"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	),
	User: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none">
			<path
				d="M12.7502 5.41671C12.7502 7.60284 10.9779 9.37504 8.79183 9.37504C6.60573 9.37504 4.8335 7.60284 4.8335 5.41671C4.8335 3.23057 6.60573 1.45837 8.79183 1.45837C10.9779 1.45837 12.7502 3.23057 12.7502 5.41671Z"
				stroke="#3E227C"
				strokeWidth="2"
			/>
			<path
				d="M16.5 18.3334V17.6667C16.5 14.353 13.8137 11.6667 10.5 11.6667H7.5C4.18629 11.6667 1.5 14.353 1.5 17.6667V18.3334"
				stroke="#3E227C"
				strokeWidth="2"
			/>
		</svg>
	),
	Close: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="12" fill="#EBE7FF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L12 10.9393L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L13.0607 12L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L12 13.0607L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L10.9393 12L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
				fill="#21065D"
			/>
		</svg>
	),
	ArrowRight: ({
		width,
		height,
		size = 24,
		wrapColor,
		color = "#9A9A9E",
		viewBox = "0 0 24 24",
		...props
	}: ISVGIcon) => (
		<SvgIconCus
			width={width}
			size={size}
			height={height}
			wrapColor={wrapColor}
			viewBox={viewBox}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
				fill={color}
				{...props}
			/>
		</SvgIconCus>
	),
	ArrowDown: () => (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4401_21665)">
				<path
					d="M10.5867 6.19305L7.99999 8.77971L5.41332 6.19305C5.15332 5.93305 4.73332 5.93305 4.47332 6.19305C4.21332 6.45305 4.21332 6.87305 4.47332 7.13305L7.53332 10.193C7.79332 10.453 8.21332 10.453 8.47332 10.193L11.5333 7.13305C11.7933 6.87305 11.7933 6.45305 11.5333 6.19305C11.2733 5.93971 10.8467 5.93305 10.5867 6.19305Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4401_21665">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	EyeHide: () => (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.53009 2.46967C3.23719 2.17678 2.76232 2.17678 2.46943 2.46967C2.17653 2.76256 2.17653 3.23744 2.46943 3.53033L4.58578 5.64668C3.61207 6.32254 2.87547 7.12109 2.35985 7.90076C1.75793 8.81095 1.4165 9.75949 1.4165 10.5C1.4165 11.0782 1.62448 11.7725 1.98309 12.461C2.34869 13.163 2.8965 13.9092 3.63696 14.5927C5.12687 15.968 7.39085 17.0833 10.4998 17.0833C12.2959 17.0833 13.814 16.711 15.0659 16.1268L17.4694 18.5303C17.7623 18.8232 18.2372 18.8232 18.5301 18.5303C18.823 18.2374 18.823 17.7626 18.5301 17.4697L3.53009 2.46967ZM13.9229 14.9838L12.2057 13.2666C11.7098 13.5729 11.125 13.75 10.4998 13.75C8.70483 13.75 7.24976 12.2949 7.24976 10.5C7.24976 9.87478 7.42685 9.28992 7.73312 8.79403L5.66715 6.72806C4.74179 7.3217 4.06395 8.04327 3.61101 8.72817C3.10202 9.49782 2.9165 10.1583 2.9165 10.5C2.9165 10.7551 3.02103 11.2066 3.31346 11.7681C3.5989 12.3161 4.04068 12.924 4.65438 13.4905C5.8728 14.6152 7.77549 15.5833 10.4998 15.5833C11.8353 15.5833 12.9706 15.3507 13.9229 14.9838ZM8.85086 9.91177C8.78535 10.0955 8.74976 10.2934 8.74976 10.5C8.74976 11.4665 9.53326 12.25 10.4998 12.25C10.7064 12.25 10.9043 12.2144 11.088 12.1489L8.85086 9.91177ZM10.4998 5.41665C9.64208 5.41665 8.86806 5.51261 8.17314 5.67765C7.77014 5.77337 7.36585 5.52426 7.27014 5.12126C7.17442 4.71826 7.42353 4.31397 7.82653 4.21825C8.63884 4.02532 9.52936 3.91665 10.4998 3.91665C13.6088 3.91665 15.8728 5.03191 17.3627 6.40721C18.1032 7.09072 18.651 7.83698 19.0166 8.53895C19.3752 9.22747 19.5832 9.92172 19.5832 10.5C19.5832 11.5532 18.8904 13.0785 17.6007 14.3644C17.3074 14.6569 16.8325 14.6562 16.5401 14.3629C16.2476 14.0695 16.2483 13.5947 16.5416 13.3022C17.6598 12.1873 18.0832 11.0216 18.0832 10.5C18.0832 10.2449 17.9786 9.79332 17.6862 9.23185C17.4008 8.68381 16.959 8.07591 16.3453 7.50942C15.1269 6.38472 13.2242 5.41665 10.4998 5.41665Z"
				fill="#000"
			/>
		</svg>
	),
	Eye: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75 12C2.75 11.6616 2.88577 11.0954 3.24331 10.409C3.59385 9.73591 4.13459 8.9926 4.88371 8.3011C6.37297 6.9264 8.69232 5.75 12 5.75C15.3077 5.75 17.627 6.9264 19.1163 8.3011C19.8654 8.9926 20.4061 9.73591 20.7567 10.409C21.1142 11.0954 21.25 11.6616 21.25 12C21.25 12.3384 21.1142 12.9046 20.7567 13.591C20.4061 14.2641 19.8654 15.0074 19.1163 15.6989C17.627 17.0736 15.3077 18.25 12 18.25C8.69232 18.25 6.37297 17.0736 4.88371 15.6989C4.13459 15.0074 3.59385 14.2641 3.24331 13.591C2.88577 12.9046 2.75 12.3384 2.75 12ZM12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM9.7499 12C9.7499 10.7574 10.7573 9.75002 11.9999 9.75002C13.2425 9.75002 14.2499 10.7574 14.2499 12C14.2499 13.2427 13.2425 14.25 11.9999 14.25C10.7573 14.25 9.7499 13.2427 9.7499 12ZM11.9999 8.25002C9.92883 8.25002 8.2499 9.92896 8.2499 12C8.2499 14.0711 9.92883 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92896 14.071 8.25002 11.9999 8.25002Z"
				fill="#000"
			/>
		</svg>
	),
	GoogleIcon: () => (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.26 12.273C24.26 11.4221 24.1837 10.6039 24.0418 9.81848H12.74V14.4603H19.1982C18.92 15.9603 18.0745 17.2312 16.8036 18.0821V21.0931H20.6818C22.9509 19.004 24.26 15.9276 24.26 12.273Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 23.9999C15.98 23.9999 18.6964 22.9254 20.6819 21.0926L16.8037 18.0817C15.7291 18.8017 14.3546 19.2272 12.74 19.2272C9.61456 19.2272 6.96909 17.1163 6.02546 14.2799H2.01636V17.389C3.99091 21.3108 8.0491 23.9999 12.74 23.9999Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.02546 14.2801C5.78546 13.5601 5.64909 12.791 5.64909 12.0001C5.64909 11.2092 5.78546 10.4401 6.02546 9.72006V6.61096H2.01636C1.20363 8.23097 0.73999 10.0637 0.73999 12.0001C0.73999 13.9364 1.20363 15.7692 2.01636 17.3892L6.02546 14.2801Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 4.77286C14.5018 4.77286 16.0837 5.37832 17.3273 6.56741L20.7691 3.12558C18.6909 1.18922 15.9746 0.00012207 12.74 0.00012207C8.0491 0.00012207 3.99091 2.68922 2.01636 6.61104L6.02546 9.72014C6.96909 6.88377 9.61455 4.77286 12.74 4.77286Z"
				fill="#EA4335"
			/>
		</svg>
	),
	UnCheckBox: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="17" height="17" rx="5.5" fill="white" />
			<rect x="1" y="1" width="17" height="17" rx="5.5" stroke="black" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6738 5.44714C14.9791 5.72703 14.9998 6.20146 14.7199 6.5068L8.3032 13.5068C8.16497 13.6576 7.97114 13.7454 7.76662 13.7498C7.56211 13.7543 7.36465 13.675 7.22 13.5303L4.30333 10.6137C4.01044 10.3208 4.01044 9.8459 4.30333 9.55301C4.59623 9.26011 5.0711 9.26011 5.36399 9.55301L7.72677 11.9158L13.6141 5.49321C13.894 5.18787 14.3685 5.16724 14.6738 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	Checked: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="18" height="18" rx="6" fill="#21065D" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6737 5.44714C14.9791 5.72703 14.9997 6.20146 14.7198 6.5068L8.30315 13.5068C8.16492 13.6576 7.97109 13.7454 7.76658 13.7498C7.56206 13.7543 7.3646 13.675 7.21995 13.5303L4.30329 10.6137C4.01039 10.3208 4.01039 9.8459 4.30329 9.55301C4.59618 9.26011 5.07105 9.26011 5.36395 9.55301L7.72673 11.9158L13.6141 5.49321C13.894 5.18787 14.3684 5.16724 14.6737 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	List: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none">
			<path
				d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM6 15H18C18.55 15 19 14.55 19 14C19 13.45 18.55 13 18 13H6C5.45 13 5 13.45 5 14C5 14.55 5.45 15 6 15ZM6 9H18C18.55 9 19 8.55 19 8C19 7.45 18.55 7 18 7H6C5.45 7 5 7.45 5 8C5 8.55 5.45 9 6 9ZM5 2C5 2.55 5.45 3 6 3H18C18.55 3 19 2.55 19 2C19 1.45 18.55 1 18 1H6C5.45 1 5 1.45 5 2Z"
				fill="#21065D"
			/>
		</svg>
	),
	Menu: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M21 18H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 14H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 10H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 6H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Notification: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="22"
			viewBox="0 0 21 22"
			fill="none">
			<path
				d="M7.02051 18C7.02051 18.625 7.09395 19.3497 7.74544 19.8021C8.39692 20.2546 9.19708 20.5 10.0205 20.5C10.8439 20.5 11.6441 20.2546 12.2956 19.8021C12.9471 19.3497 13.0205 18.625 13.0205 18"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.51946 8.5C3.51946 4.91015 6.42961 2 10.0195 2V0C5.32504 0 1.51946 3.80558 1.51946 8.5V11.6074C1.51946 12.295 1.29644 12.964 0.883891 13.5141L0.620824 13.8648C0.336017 14.2446 -0.140295 14.9809 0.0394786 15.8658C0.32105 17.2517 1.53111 17.721 2.44274 17.8851C3.19781 18.021 4.07554 18.0109 4.74867 18.0032C4.89215 18.0015 5.0264 18 5.14812 18H10.0195V16H5.14812C4.97806 16 4.8095 16.0016 4.64317 16.0032C3.97644 16.0095 3.34551 16.0155 2.79707 15.9168C2.11669 15.7943 2.0277 15.6006 2.00052 15.4728C2.00112 15.4643 2.00461 15.4405 2.02161 15.3965C2.0539 15.3129 2.1174 15.2027 2.22082 15.0648L2.48389 14.7141C3.15609 13.8178 3.51946 12.7277 3.51946 11.6074V8.5ZM2.00048 15.4765C2.00044 15.4765 2.00039 15.4758 2.00044 15.4743L2.00048 15.4765Z"
				fill="#3A3A3C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5203 8.5C16.5203 4.91015 13.6093 2 10.0195 2V0C14.7139 0 18.5203 3.80558 18.5203 8.5V11.6074C18.5203 12.295 18.7433 12.964 19.1558 13.5141L19.4189 13.8648C19.7037 14.2446 20.18 14.9809 20.0002 15.8658C19.7187 17.2517 18.5086 17.721 17.597 17.8851C16.8419 18.021 15.9642 18.0109 15.291 18.0032C15.1476 18.0015 15.0133 18 14.8916 18H10.0195V16H14.8916C15.0617 16 15.2302 16.0016 15.3966 16.0032C16.0633 16.0095 16.6942 16.0155 17.2426 15.9168C17.923 15.7943 18.012 15.6006 18.0392 15.4728C18.0386 15.4643 18.0351 15.4405 18.0181 15.3965C17.9858 15.3129 17.9223 15.2027 17.8189 15.0648L17.5558 14.7141C16.8836 13.8178 16.5203 12.7277 16.5203 11.6074V8.5ZM18.0392 15.4765C18.0393 15.4765 18.0393 15.4758 18.0393 15.4743L18.0392 15.4765Z"
				fill="#3A3A3C"
			/>
		</svg>
	),
	Home: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none">
			<path
				d="M1 19V8.67412C1 8.04041 1.30033 7.4442 1.80954 7.067L8.80955 1.88182C9.51674 1.35797 10.4833 1.35797 11.1905 1.88182L18.1905 7.067C18.6997 7.4442 19 8.04041 19 8.67412V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19Z"
				stroke={color ?? "#3E227C"}
				strokeWidth="2"
			/>
			<path
				d="M6.5 21V14C6.5 13.1716 7.17157 12.5 8 12.5H12C12.8284 12.5 13.5 13.1716 13.5 14V21"
				stroke={color ?? "#3E227C"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Setting: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M13.7462 10.9472C13.7462 12.4812 12.5188 13.7499 11 13.7499C9.48124 13.7499 8.24512 12.4812 8.24512 10.9472C8.24512 9.41318 9.48124 8.24988 11 8.24988C12.5188 8.24988 13.7462 9.41318 13.7462 10.9472Z"
				stroke="#3A3A3C"
				strokeWidth="2"
			/>
			<path
				d="M13.2127 3.04115L12.5749 1.5962C12.4149 1.23381 12.0562 1 11.66 1H10.3151C9.91901 1 9.56023 1.23381 9.40028 1.5962L8.7625 3.04115C8.14903 4.43103 6.70895 5.26643 5.19788 5.10901L3.39332 4.92102C3.00234 4.88029 2.62377 5.07239 2.42579 5.41199L1.83283 6.42909C1.63099 6.77531 1.65534 7.2086 1.89471 7.53002L2.92975 8.91989C3.84991 10.1555 3.85394 11.8475 2.93967 13.0875L1.89016 14.5108C1.65335 14.832 1.63015 15.2632 1.83113 15.608L2.42295 16.6231C2.62238 16.9652 3.00486 17.1573 3.39835 17.1132L5.17891 16.9134C6.69885 16.7429 8.15349 17.5791 8.77109 18.9783L9.40028 20.4038C9.56023 20.7662 9.91901 21 10.3151 21H11.66C12.0562 21 12.4149 20.7662 12.5749 20.4038L13.2041 18.9783C13.8217 17.5791 15.2763 16.7429 16.7963 16.9134L18.5829 17.1139C18.9733 17.1577 19.3531 16.9688 19.5539 16.6312L20.1632 15.6064C20.3676 15.2627 20.3476 14.8304 20.1124 14.5071L19.0664 13.069C18.1701 11.8368 18.174 10.1661 19.0762 8.93811L20.1079 7.53379C20.3456 7.2102 20.3667 6.77581 20.1615 6.43068L19.5511 5.40395C19.3518 5.06875 18.9758 4.87998 18.5879 4.92039L16.7773 5.10901C15.2662 5.26643 13.8261 4.43103 13.2127 3.04115Z"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	),
	Help: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="17"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke="#3E227C"
				strokeWidth="2"
			/>
			<path
				d="M8.5 7.71806C8.63486 7.36247 8.83863 7.04373 9.09392 6.78019C9.56143 6.29757 10.2017 6 10.908 6C12.3395 6 13.5 7.22255 13.5 8.73065C13.5 10.0598 12.5986 11.1671 11.4047 11.4112C11.1342 11.4665 10.908 11.6852 10.908 11.9613V13"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M11 17.25C11.5523 17.25 12 16.8023 12 16.25C12 15.6977 11.5523 15.25 11 15.25C10.4477 15.25 10 15.6977 10 16.25C10 16.8023 10.4477 17.25 11 17.25Z"
				fill="#3E227C"
			/>
		</svg>
	),
	Logout: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M16 17L21 12L16 7"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 12H9"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Plus: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	PlusWhite: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Upload: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none">
			<path
				d="M1.08301 15.0942H16.9163"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M8.99971 10.6962V1.8999M8.99971 1.8999L4.60156 5.15779M8.99971 1.8999L13.3979 5.15779"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Edit: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 21 20"
			fill="none">
			<path
				d="M10.5 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V9.5"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M16.6888 6.85937L10.3566 13.146C10.1225 13.3784 9.83456 13.5493 9.51841 13.6434L5.76128 14.7625C5.57411 14.8182 5.39816 14.6474 5.4483 14.4587L6.36383 11.0126C6.45307 10.6767 6.6283 10.3698 6.87226 10.1223L13.3642 3.53472M16.6888 6.85937L18.5055 5.04267C19.2866 4.26162 19.2866 2.99529 18.5055 2.21424L18.0093 1.71802C17.2283 0.93697 15.9619 0.936969 15.1809 1.71802L13.3642 3.53472M16.6888 6.85937L13.3642 3.53472"
				stroke="#3E227C"
				strokeWidth="2"
			/>
		</svg>
	),
	Delete: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M3.5 4L3.5 19C3.5 20.1046 4.39543 21 5.5 21H16.5C17.6046 21 18.5 20.1046 18.5 19V4"
				stroke="#3E227C"
				strokeWidth="2"
			/>
			<path
				d="M9 10V15"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13 10V15"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1.5 4H20.5"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M6 4L6.22147 2.6712C6.3822 1.70683 7.21658 1 8.19425 1H11H13.8057C14.7834 1 15.6178 1.70683 15.7785 2.6712L16 4"
				stroke="#3E227C"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	),
	Back: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none">
			<path
				d="M19 9L1 9M1 9L8 1.5M1 9L8 16.5"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Sparkers: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none">
			<path
				d="M0.5 12.4982C0.621001 12.5602 0.71096 12.6079 0.801552 12.6523C2.80662 13.6437 4.81105 14.6369 6.81929 15.6232C6.99287 15.7084 7.10184 15.8244 7.18673 15.9935C8.19148 17.9931 9.20257 19.9896 10.2118 21.9867C10.2498 22.0618 10.2909 22.1364 10.3442 22.2373C10.8383 21.2598 11.3172 20.3117 11.7968 19.3636C12.3758 18.2181 12.9517 17.072 13.539 15.9303C13.5941 15.8225 13.7005 15.7178 13.8095 15.6633C15.8462 14.6482 17.8874 13.6405 19.9273 12.631C20.0033 12.5934 20.0781 12.5521 20.1769 12.5001C20.0838 12.4499 20.0173 12.4117 19.9488 12.3779C17.9159 11.3728 15.8817 10.3702 13.8532 9.35689C13.7182 9.28984 13.5865 9.16075 13.5181 9.02791C12.4968 7.0277 11.4864 5.02185 10.4734 3.01725C10.4385 2.9477 10.4012 2.87939 10.3448 2.77287C10.2935 2.86561 10.2529 2.93328 10.2175 3.00347C9.20763 5.00054 8.19655 6.99699 7.19116 8.99658C7.10247 9.17266 6.98907 9.29172 6.80725 9.3807C4.79268 10.3702 2.78191 11.3684 0.769876 12.3647C0.692588 12.4029 0.615299 12.4418 0.5 12.4988L0.5 12.4982ZM10.3429 7.24389C10.3961 7.3454 10.436 7.41997 10.474 7.49579C10.9897 8.51469 11.5022 9.53548 12.0268 10.55C12.0857 10.664 12.1984 10.7731 12.3137 10.832C13.1348 11.2499 13.9634 11.6535 14.7889 12.0627C15.0689 12.2012 15.3483 12.3415 15.6574 12.4963C15.5611 12.5471 15.4933 12.5847 15.4243 12.6185C14.3942 13.1292 13.3616 13.6355 12.3366 14.1556C12.2079 14.2208 12.0819 14.3411 12.016 14.4677C11.4933 15.4747 10.9859 16.4892 10.474 17.5018C10.436 17.577 10.3967 17.651 10.3429 17.755C10.2916 17.6591 10.2536 17.5921 10.2187 17.5238C9.69924 16.4974 9.18356 15.4697 8.65584 14.4476C8.59693 14.3336 8.4848 14.2221 8.3695 14.1638C7.33687 13.6418 6.29791 13.1317 5.26085 12.6179C5.1918 12.5834 5.12401 12.5464 5.02962 12.4969C5.13478 12.4437 5.21017 12.4042 5.28682 12.3666C6.30298 11.864 7.3223 11.3665 8.33339 10.8533C8.46832 10.785 8.59883 10.6559 8.66788 10.5224C9.19496 9.50916 9.70621 8.48712 10.2225 7.46822C10.2574 7.39992 10.2947 7.33349 10.3429 7.24326V7.24389Z"
				fill="white"
			/>
			<path
				d="M15.4102 4.49426C15.9328 4.75368 16.4105 4.99118 16.8888 5.22804C17.3405 5.45175 17.7966 5.66731 18.2413 5.90355C18.356 5.96434 18.4675 6.074 18.5264 6.18867C18.9629 7.02898 19.3854 7.87619 19.8131 8.72152C19.8511 8.79608 19.8929 8.86877 19.9493 8.97217C20.0044 8.87567 20.0487 8.80611 20.0855 8.73342C20.5137 7.88872 20.9357 7.04089 21.3728 6.2012C21.4355 6.08089 21.5521 5.96308 21.6737 5.90105C22.5226 5.46804 23.3791 5.05071 24.2331 4.62773C24.3078 4.59076 24.3801 4.54815 24.4821 4.493C24.382 4.43786 24.311 4.39462 24.2369 4.35765C23.3759 3.93154 22.5125 3.50982 21.6553 3.07619C21.5457 3.02105 21.4393 2.9164 21.3835 2.80862C20.9097 1.89123 20.4479 0.967574 19.9588 0.000680923C19.9024 0.100943 19.8612 0.167991 19.8257 0.238173C19.3943 1.08977 18.9686 1.94449 18.5277 2.79107C18.465 2.91139 18.3452 3.02543 18.2236 3.08747C17.3816 3.51671 16.5321 3.93154 15.6857 4.35139C15.6091 4.38961 15.5337 4.43034 15.4102 4.49426Z"
				fill="white"
			/>
			<path
				d="M17.4336 20.5109C18.1856 20.8831 18.9002 21.2309 19.6084 21.5925C19.7111 21.6445 19.8099 21.7435 19.8631 21.845C20.1976 22.4823 20.5182 23.1265 20.8438 23.7681C20.8786 23.8364 20.9166 23.9029 20.9686 24C21.3341 23.2794 21.6769 22.5857 22.0392 21.9027C22.1096 21.7704 22.2382 21.6426 22.3718 21.5731C23.0624 21.2146 23.7637 20.8756 24.4998 20.5103C24.4003 20.4577 24.3351 20.4213 24.2679 20.3881C23.6401 20.0767 23.0142 19.7621 22.3826 19.4582C22.2185 19.3792 22.1077 19.2764 22.0266 19.1104C21.6864 18.4161 21.3316 17.7293 20.9667 17.0093C20.8717 17.1942 20.7887 17.3521 20.7082 17.5112C20.4396 18.0414 20.1666 18.5696 19.9068 19.1041C19.8276 19.2677 19.7218 19.3773 19.5552 19.4569C18.923 19.7596 18.2977 20.0748 17.6699 20.3862C17.6002 20.4207 17.5324 20.4583 17.4342 20.5097L17.4336 20.5109Z"
				fill="white"
			/>
		</svg>
	),
	Sparkers_2: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="34"
			height="34"
			viewBox="0 0 25 24"
			fill="none">
			<path
				d="M0.5 12.4982C0.621001 12.5602 0.71096 12.6079 0.801552 12.6523C2.80662 13.6437 4.81105 14.6369 6.81929 15.6232C6.99287 15.7084 7.10184 15.8244 7.18673 15.9935C8.19148 17.9931 9.20257 19.9896 10.2118 21.9867C10.2498 22.0618 10.2909 22.1364 10.3442 22.2373C10.8383 21.2598 11.3172 20.3117 11.7968 19.3636C12.3758 18.2181 12.9517 17.072 13.539 15.9303C13.5941 15.8225 13.7005 15.7178 13.8095 15.6633C15.8462 14.6482 17.8874 13.6405 19.9273 12.631C20.0033 12.5934 20.0781 12.5521 20.1769 12.5001C20.0838 12.4499 20.0173 12.4117 19.9488 12.3779C17.9159 11.3728 15.8817 10.3702 13.8532 9.35689C13.7182 9.28984 13.5865 9.16075 13.5181 9.02791C12.4968 7.0277 11.4864 5.02185 10.4734 3.01725C10.4385 2.9477 10.4012 2.87939 10.3448 2.77287C10.2935 2.86561 10.2529 2.93328 10.2175 3.00347C9.20763 5.00054 8.19655 6.99699 7.19116 8.99658C7.10247 9.17266 6.98907 9.29172 6.80725 9.3807C4.79268 10.3702 2.78191 11.3684 0.769876 12.3647C0.692588 12.4029 0.615299 12.4418 0.5 12.4988L0.5 12.4982ZM10.3429 7.24389C10.3961 7.3454 10.436 7.41997 10.474 7.49579C10.9897 8.51469 11.5022 9.53548 12.0268 10.55C12.0857 10.664 12.1984 10.7731 12.3137 10.832C13.1348 11.2499 13.9634 11.6535 14.7889 12.0627C15.0689 12.2012 15.3483 12.3415 15.6574 12.4963C15.5611 12.5471 15.4933 12.5847 15.4243 12.6185C14.3942 13.1292 13.3616 13.6355 12.3366 14.1556C12.2079 14.2208 12.0819 14.3411 12.016 14.4677C11.4933 15.4747 10.9859 16.4892 10.474 17.5018C10.436 17.577 10.3967 17.651 10.3429 17.755C10.2916 17.6591 10.2536 17.5921 10.2187 17.5238C9.69924 16.4974 9.18356 15.4697 8.65584 14.4476C8.59693 14.3336 8.4848 14.2221 8.3695 14.1638C7.33687 13.6418 6.29791 13.1317 5.26085 12.6179C5.1918 12.5834 5.12401 12.5464 5.02962 12.4969C5.13478 12.4437 5.21017 12.4042 5.28682 12.3666C6.30298 11.864 7.3223 11.3665 8.33339 10.8533C8.46832 10.785 8.59883 10.6559 8.66788 10.5224C9.19496 9.50916 9.70621 8.48712 10.2225 7.46822C10.2574 7.39992 10.2947 7.33349 10.3429 7.24326V7.24389Z"
				fill="#B9B8FD"
			/>
			<path
				d="M15.4102 4.49426C15.9328 4.75368 16.4105 4.99118 16.8888 5.22804C17.3405 5.45175 17.7966 5.66731 18.2413 5.90355C18.356 5.96434 18.4675 6.074 18.5264 6.18867C18.9629 7.02898 19.3854 7.87619 19.8131 8.72152C19.8511 8.79608 19.8929 8.86877 19.9493 8.97217C20.0044 8.87567 20.0487 8.80611 20.0855 8.73342C20.5137 7.88872 20.9357 7.04089 21.3728 6.2012C21.4355 6.08089 21.5521 5.96308 21.6737 5.90105C22.5226 5.46804 23.3791 5.05071 24.2331 4.62773C24.3078 4.59076 24.3801 4.54815 24.4821 4.493C24.382 4.43786 24.311 4.39462 24.2369 4.35765C23.3759 3.93154 22.5125 3.50982 21.6553 3.07619C21.5457 3.02105 21.4393 2.9164 21.3835 2.80862C20.9097 1.89123 20.4479 0.967574 19.9588 0.000680923C19.9024 0.100943 19.8612 0.167991 19.8257 0.238173C19.3943 1.08977 18.9686 1.94449 18.5277 2.79107C18.465 2.91139 18.3452 3.02543 18.2236 3.08747C17.3816 3.51671 16.5321 3.93154 15.6857 4.35139C15.6091 4.38961 15.5337 4.43034 15.4102 4.49426Z"
				fill="#B9B8FD"
			/>
			<path
				d="M17.4336 20.5109C18.1856 20.8831 18.9002 21.2309 19.6084 21.5925C19.7111 21.6445 19.8099 21.7435 19.8631 21.845C20.1976 22.4823 20.5182 23.1265 20.8438 23.7681C20.8786 23.8364 20.9166 23.9029 20.9686 24C21.3341 23.2794 21.6769 22.5857 22.0392 21.9027C22.1096 21.7704 22.2382 21.6426 22.3718 21.5731C23.0624 21.2146 23.7637 20.8756 24.4998 20.5103C24.4003 20.4577 24.3351 20.4213 24.2679 20.3881C23.6401 20.0767 23.0142 19.7621 22.3826 19.4582C22.2185 19.3792 22.1077 19.2764 22.0266 19.1104C21.6864 18.4161 21.3316 17.7293 20.9667 17.0093C20.8717 17.1942 20.7887 17.3521 20.7082 17.5112C20.4396 18.0414 20.1666 18.5696 19.9068 19.1041C19.8276 19.2677 19.7218 19.3773 19.5552 19.4569C18.923 19.7596 18.2977 20.0748 17.6699 20.3862C17.6002 20.4207 17.5324 20.4583 17.4342 20.5097L17.4336 20.5109Z"
				fill="#B9B8FD"
			/>
		</svg>
	),
	Gallery: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M2.08301 16.4166V3.58325C2.08301 2.75482 2.75458 2.08325 3.58301 2.08325H7.18023C8.00866 2.08325 8.68023 2.75482 8.68023 3.58325V16.4166C8.68023 17.245 8.00866 17.9166 7.18023 17.9166H3.58301C2.75458 17.9166 2.08301 17.245 2.08301 16.4166Z"
				stroke="#9577D9"
				strokeWidth="2"
			/>
			<path
				d="M11.3193 7.18048V3.58325C11.3193 2.75482 11.9909 2.08325 12.8193 2.08325H16.4166C17.245 2.08325 17.9166 2.75482 17.9166 3.58325V7.18047C17.9166 8.0089 17.245 8.68047 16.4166 8.68047H12.8193C11.9909 8.68047 11.3193 8.0089 11.3193 7.18048Z"
				stroke="#9577D9"
				strokeWidth="2"
			/>
			<path
				d="M11.3193 16.4168V12.8196C11.3193 11.9912 11.9909 11.3196 12.8193 11.3196H16.4166C17.245 11.3196 17.9166 11.9912 17.9166 12.8196V16.4168C17.9166 17.2452 17.245 17.9168 16.4166 17.9168H12.8193C11.9909 17.9168 11.3193 17.2452 11.3193 16.4168Z"
				stroke="#9577D9"
				strokeWidth="2"
			/>
		</svg>
	),
	ArrowSelect: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="8"
			viewBox="0 0 14 8"
			fill="none">
			<path
				d="M12.333 1.33341L7.70678 5.95964C7.31626 6.35017 6.68309 6.35016 6.29257 5.95964L1.66634 1.33341"
				stroke="#3B3899"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Should: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none">
			<g clipPath="url(#clip0_957_6161)">
				<path
					d="M7.38024 0H8.66078C8.67413 0.0283437 8.70085 0.0287605 8.72631 0.0300109C9.20755 0.0591882 9.67836 0.152556 10.1412 0.282186C10.7143 0.442661 11.2698 0.647736 11.7849 0.952431C11.7786 1.02704 11.8045 1.0954 11.8229 1.16542C12.0963 2.21873 12.2027 3.28453 12.0675 4.36909C11.4101 4.16068 10.8771 4.34367 10.4088 4.84343C9.23426 6.09639 8.04222 7.33267 6.86144 8.57979C6.76126 8.68567 6.70867 8.70317 6.5989 8.58688C6.12308 8.0842 5.64393 7.58443 5.15183 7.09759C4.51824 6.47111 3.4656 6.60116 2.98728 7.35185C2.62081 7.92789 2.70846 8.61981 3.21976 9.14917C4.07623 10.0362 4.93521 10.9211 5.79293 11.8072C5.77373 11.8151 5.75495 11.8247 5.73492 11.8301C4.43518 12.179 3.12334 12.2303 1.80065 11.986C1.5256 11.9352 1.25305 11.8706 0.979242 11.8126C0.957121 11.778 0.932078 11.7451 0.913296 11.7089C0.0839534 10.117 -0.174825 8.42932 0.113588 6.66493C0.367357 5.11436 1.03726 3.75304 2.10284 2.5972C3.36835 1.22586 4.92352 0.383056 6.77086 0.0917001C6.97371 0.0596051 7.17698 0.0304278 7.38024 0Z"
					fill="#74D97F"
				/>
				<path
					d="M12.0667 4.36954C12.202 3.28498 12.0955 2.21918 11.8222 1.16588C11.8038 1.09585 11.7779 1.02707 11.7842 0.952881C14.0798 2.2446 15.4576 4.18573 15.9079 6.78459C15.9422 6.98091 15.9296 7.18432 15.9993 7.37522V8.62526C15.9409 8.77073 15.9505 8.92704 15.9288 9.07834C15.7038 10.6598 15.0777 12.0619 14.0218 13.2599C12.7283 14.7271 11.1118 15.6153 9.17469 15.9138C8.98227 15.9433 8.78485 15.9363 8.59744 15.9996H7.37952C7.36282 15.9634 7.32818 15.9713 7.29854 15.9688C5.94288 15.8458 4.69616 15.4107 3.56588 14.6525C2.47233 13.9189 1.60543 12.9743 0.978516 11.8127C1.25232 11.871 1.52487 11.9352 1.79993 11.9861C3.12262 12.2303 4.43446 12.1791 5.73419 11.8302C5.75422 11.8248 5.77259 11.8152 5.79221 11.8073C6.46378 12.3545 7.31983 12.2391 7.90167 11.5838C8.52649 10.8802 9.1943 10.2146 9.84375 9.53226C10.7441 8.58608 11.6452 7.64115 12.5438 6.69372C12.9299 6.28691 13.0564 5.8084 12.8957 5.26904C12.7671 4.83846 12.4691 4.55294 12.0667 4.36913V4.36954Z"
					fill="#2AD252"
				/>
				<path
					d="M12.0669 4.36955C12.4693 4.55337 12.7677 4.83889 12.8958 5.26946C13.0565 5.80882 12.9301 6.28733 12.544 6.69415C11.6449 7.64158 10.7442 8.5865 9.84392 9.53268C9.19489 10.215 8.52707 10.8807 7.90183 11.5843C7.32 12.2395 6.46394 12.355 5.79237 11.8077C4.93465 10.9219 4.07609 10.0366 3.2192 9.14962C2.7079 8.62027 2.61983 7.92835 2.98672 7.3523C3.46462 6.6012 4.51768 6.47157 5.15127 7.09804C5.64336 7.58447 6.12252 8.08424 6.59834 8.58734C6.70811 8.70363 6.7607 8.68612 6.86087 8.58025C8.04165 7.33313 9.2337 6.09685 10.4082 4.84389C10.8765 4.34412 11.4091 4.16156 12.0669 4.36955Z"
					fill="#FDFEFE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_957_6161">
					<rect width={16} height={16} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	NotShould: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none">
			<g clipPath="url(#clip0_957_6185)">
				<path
					d="M7.40795 0.00476074H8.56409C8.58705 0.0459244 8.62782 0.0320713 8.66186 0.0344461C9.26071 0.0787762 9.85125 0.180894 10.4161 0.380379C13.5061 1.47201 15.3462 3.62993 15.9173 6.86287C15.9514 7.05483 15.9411 7.25273 16.0001 7.44074V8.59688C15.951 8.62934 15.9751 8.6804 15.968 8.72354C15.9466 8.8593 15.9557 8.99942 15.9098 9.13162C15.9114 9.04058 15.8865 8.96775 15.8164 8.8977C14.5554 7.64141 13.2975 6.38196 12.0385 5.1241C12.0001 5.0857 11.97 5.02792 11.8999 5.03821L11.9059 5.03939C11.9039 5.01921 11.8932 5.00733 11.873 5.00456L11.8758 5.00733C11.8647 4.99586 11.8536 4.98438 11.8425 4.9725L11.8461 4.97488C11.8346 4.9634 11.8231 4.95153 11.8117 4.94005L11.8136 4.94401C11.7673 4.82447 11.6822 4.74333 11.5659 4.69267C11.4281 4.55889 11.2888 4.42629 11.1527 4.29093C10.6975 3.83892 10.1501 3.83852 9.6957 4.29172C9.1764 4.81022 8.65552 5.32675 8.14098 5.8504C8.04915 5.94381 8.00205 5.95885 7.90152 5.85515C7.37668 5.31289 6.84472 4.77777 6.31117 4.24422C5.88568 3.81873 5.3276 3.82111 4.89894 4.24462C4.6951 4.44569 4.49364 4.64874 4.29178 4.85178C3.83819 5.30696 3.83739 5.85436 4.29019 6.30834C4.81939 6.83872 5.34739 7.37068 5.88212 7.89591C5.97236 7.98457 5.96286 8.03009 5.87856 8.11282C5.33393 8.65032 4.79247 9.19059 4.25457 9.73482C3.85085 10.1429 3.85204 10.7026 4.25061 11.113C4.40617 11.2729 4.56409 11.4308 4.72083 11.5896C4.61673 11.6778 4.74577 11.6984 4.77228 11.7253C6.12396 13.0841 7.48038 14.4382 8.83324 15.7962C8.9128 15.8757 8.99314 15.9209 9.10357 15.9133C9.07943 15.9216 9.05529 15.9339 9.03035 15.9371C7.65572 16.1033 6.32226 15.9418 5.03312 15.4312C1.41468 13.9988 -0.660132 10.0815 0.18966 6.28697C0.923086 3.01367 3.53698 0.586198 6.86134 0.0870881C7.04341 0.0597776 7.23102 0.0661104 7.40834 0.00476074H7.40795Z"
					fill="#F05249"
				/>
				<path
					d="M15.9102 9.13164C15.7859 10.0962 15.4859 11.005 15.0086 11.8496C13.8077 13.9743 12.006 15.2959 9.62681 15.831C9.45503 15.8698 9.27811 15.8864 9.10356 15.9134C8.99313 15.9209 8.91278 15.8758 8.83322 15.7962C7.48036 14.4382 6.12394 13.0841 4.77227 11.7253C4.74575 11.6984 4.61632 11.6782 4.72081 11.59C4.75485 11.622 4.78929 11.6533 4.82254 11.6862C5.31492 12.1777 5.84054 12.1793 6.33332 11.6913C6.8542 11.1752 7.37468 10.659 7.89714 10.1445C7.93514 10.1069 7.96166 10.0448 8.0333 10.0558C8.04478 10.0729 8.05428 10.0919 8.06853 10.1061C8.62938 10.6638 9.18588 11.2254 9.75347 11.7764C10.1552 12.1663 10.6983 12.1588 11.1055 11.7697C11.3311 11.554 11.5508 11.3319 11.7681 11.1079C12.1865 10.6757 12.1829 10.1188 11.7602 9.69328C11.4815 9.41266 11.1997 9.1352 10.9175 8.85814C10.6361 8.58187 10.3523 8.30757 10.0693 8.03249C10.0626 7.96164 10.1243 7.93631 10.1623 7.89871C10.686 7.37783 11.2116 6.85853 11.7364 6.33845C11.8215 6.25414 11.9035 6.16706 11.9593 6.0598C12.1425 5.70793 12.0954 5.36912 11.8999 5.03942C11.97 5.02912 12.0001 5.08691 12.0384 5.12531C13.2979 6.38317 14.5554 7.64262 15.8164 8.89891C15.8869 8.96896 15.9114 9.04179 15.9098 9.13283L15.9102 9.13164Z"
					fill="#CE3D3A"
				/>
				<path
					d="M11.8999 5.03822C12.0954 5.36792 12.1425 5.70713 11.9592 6.0586C11.9034 6.16586 11.8215 6.25334 11.7364 6.33725C11.2116 6.85694 10.6859 7.37623 10.1623 7.89751C10.1243 7.93551 10.0621 7.96084 10.0693 8.03169C10.0052 8.03525 9.96992 8.08433 9.92995 8.12391C9.32595 8.72593 8.72195 9.32795 8.11914 9.93076C8.08352 9.96638 8.03523 9.99527 8.03325 10.055C7.96161 10.044 7.93509 10.1061 7.8971 10.1437C7.37503 10.6582 6.85415 11.1744 6.33327 11.6905C5.8405 12.1789 5.31526 12.1773 4.82249 11.6854C4.78964 11.6525 4.7548 11.6212 4.72077 11.5892C4.56403 11.4305 4.4061 11.2725 4.25055 11.1126C3.85197 10.7022 3.85079 10.1425 4.25451 9.73444C4.79241 9.1906 5.33387 8.64993 5.87849 8.11243C5.9628 8.02931 5.9723 7.98419 5.88206 7.89553C5.34772 7.3703 4.81972 6.83834 4.29013 6.30796C3.83733 5.85437 3.83852 5.30657 4.29171 4.8514C4.49357 4.64875 4.69543 4.4453 4.89888 4.24423C5.32793 3.82072 5.88562 3.81835 6.31111 4.24384C6.84465 4.77738 7.37701 5.31251 7.90145 5.85476C8.00198 5.95846 8.04909 5.94342 8.14091 5.85001C8.65506 5.32676 9.17634 4.80984 9.69563 4.29133C10.15 3.83814 10.6974 3.83853 11.1526 4.29054C11.2887 4.42591 11.4281 4.5585 11.5658 4.69228C11.6485 4.77619 11.7309 4.85971 11.8136 4.94362L11.8116 4.93966C11.8231 4.95114 11.8346 4.96301 11.846 4.97449L11.8425 4.97212C11.8452 4.99191 11.854 5.00576 11.8757 5.00695L11.873 5.00418C11.8737 5.02555 11.8809 5.04099 11.9058 5.03901L11.8999 5.03782V5.03822Z"
					fill="#FEFEFE"
				/>
				<path
					d="M11.8132 4.94396C11.7305 4.86005 11.6482 4.77654 11.5654 4.69263C11.6818 4.74329 11.7669 4.82443 11.8132 4.94396Z"
					fill="#AD0F0F"
				/>
				<path
					d="M11.845 4.97489C11.8335 4.96342 11.822 4.95194 11.8105 4.94006C11.822 4.95154 11.8335 4.96342 11.845 4.97489Z"
					fill="#AD0F0F"
				/>
				<path
					d="M11.875 5.007C11.8533 5.00581 11.8446 4.99196 11.8418 4.97217C11.8529 4.98365 11.864 4.99512 11.875 5.007Z"
					fill="#AD0F0F"
				/>
				<path
					d="M11.9049 5.03935C11.88 5.04093 11.8729 5.02549 11.8721 5.00452C11.8923 5.00768 11.9029 5.01916 11.9049 5.03935Z"
					fill="#AD0F0F"
				/>
				<path
					d="M8.03223 10.055C8.03421 9.9952 8.08249 9.96631 8.11812 9.93069C8.72132 9.32788 9.32492 8.72586 9.92892 8.12384C9.9689 8.08426 10.0037 8.03518 10.0682 8.03162C10.3508 8.3067 10.6346 8.58099 10.9165 8.85726C11.1987 9.13433 11.4805 9.41179 11.7591 9.69241C12.1818 10.1175 12.1854 10.6744 11.767 11.107C11.5501 11.3314 11.3301 11.5531 11.1045 11.7688C10.6972 12.1579 10.1541 12.1654 9.75239 11.7755C9.18481 11.225 8.62831 10.6629 8.06745 10.1052C8.0532 10.091 8.0441 10.072 8.03223 10.055Z"
					fill="#FEFEFE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_957_6185">
					<rect
						width={16}
						height={15.9953}
						fill="white"
						transform="translate(0 0.00476074)"
					/>
				</clipPath>
			</defs>
		</svg>
	),
	Reset: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M7.25 8.0835H2.66667V3.50016"
				stroke="#131313"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.69606 7.32318C5.72681 3.28908 10.6433 1.66504 14.6774 3.69578C18.7115 5.72652 20.3356 10.643 18.3048 14.6771C16.2741 18.7112 11.3576 20.3353 7.32346 18.3045C5.33268 17.3024 3.92883 15.5975 3.26148 13.6426"
				stroke="#121219"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Undo: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none">
			<path
				d="M7.99967 11.6666L3.83301 7.49992L7.99967 3.33325"
				stroke="#131313"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.1663 16.6667V10.8333C17.1663 9.94928 16.8152 9.10143 16.19 8.47631C15.5649 7.85119 14.7171 7.5 13.833 7.5H3.83301"
				stroke="#121219"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Redo: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M12.5 11.6666L16.6667 7.49992L12.5 3.33325"
				stroke="#131313"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.33301 16.6667V10.8333C3.33301 9.94928 3.6842 9.10143 4.30932 8.47631C4.93444 7.85119 5.78229 7.5 6.66634 7.5H16.6663"
				stroke="#121219"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Transaction: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="12"
			viewBox="0 0 20 12"
			fill="none">
			<path
				d="M1 5.5H3.41886C4.06451 5.5 4.63772 5.08685 4.84189 4.47434L5.73656 1.79032C6.01829 0.945138 7.18124 0.862489 7.57967 1.65934L9.5 5.5L11.6056 9.71115C11.9741 10.4482 13.0259 10.4482 13.3944 9.71115L15.0854 6.32918C15.3395 5.821 15.8589 5.5 16.4271 5.5H19"
				stroke="#3E227C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	LoadingGen: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={36}
			height={37}
			viewBox="0 0 36 37"
			fill="none">
			<path
				d="M3 20.7025C3.17142 20.7904 3.29887 20.8579 3.42721 20.9209C6.2678 22.3253 9.10749 23.7324 11.9526 25.1298C12.1985 25.2505 12.3528 25.4147 12.4731 25.6544C13.8965 28.4872 15.329 31.3156 16.7587 34.1449C16.8125 34.2514 16.8709 34.3571 16.9463 34.5C17.6463 33.1151 18.3248 31.7719 19.0042 30.4288C19.8245 28.8059 20.6404 27.1822 21.4723 25.5648C21.5504 25.4121 21.7012 25.2638 21.8556 25.1866C24.741 23.7484 27.6328 22.3209 30.5227 20.8907C30.6304 20.8375 30.7363 20.7789 30.8764 20.7052C30.7444 20.6342 30.6502 20.58 30.5533 20.5321C27.6732 19.1081 24.7913 17.6877 21.9175 16.2522C21.7263 16.1572 21.5397 15.9744 21.4427 15.7862C19.996 12.9524 18.5644 10.1107 17.1293 7.27082C17.08 7.17228 17.027 7.07552 16.9472 6.9246C16.8745 7.05599 16.817 7.15186 16.7668 7.25129C15.3361 10.0806 13.9037 12.9089 12.4794 15.7418C12.3537 15.9912 12.1931 16.1599 11.9355 16.286C9.08146 17.6877 6.2328 19.1019 3.38234 20.5134C3.27284 20.5676 3.16335 20.6226 3 20.7034L3 20.7025ZM16.9445 13.2587C17.0198 13.4025 17.0764 13.5082 17.1302 13.6156C17.8608 15.0591 18.5869 16.5052 19.33 17.9425C19.4135 18.1041 19.5732 18.2585 19.7366 18.342C20.8997 18.9341 22.0737 19.5058 23.2431 20.0855C23.6398 20.2817 24.0356 20.4806 24.4736 20.6999C24.3372 20.7718 24.2411 20.825 24.1433 20.873C22.684 21.5965 21.221 22.3138 19.7689 23.0506C19.5867 23.143 19.4081 23.3134 19.3148 23.4927C18.5743 24.9194 17.8554 26.3566 17.1302 27.7912C17.0764 27.8978 17.0207 28.0025 16.9445 28.1499C16.8718 28.0141 16.8179 27.9191 16.7685 27.8223C16.0326 26.3682 15.302 24.9123 14.5544 23.4643C14.471 23.3028 14.3121 23.1447 14.1487 23.0622C12.6858 22.3227 11.2139 21.6001 9.74471 20.8721C9.64689 20.8233 9.55085 20.7709 9.41713 20.7008C9.56611 20.6253 9.67291 20.5694 9.78151 20.5161C11.2211 19.8041 12.6652 19.0993 14.0976 18.3722C14.2888 18.2754 14.4736 18.0925 14.5715 17.9034C15.3182 16.4679 16.0425 15.02 16.7739 13.5765C16.8233 13.4798 16.8762 13.3857 16.9445 13.2578V13.2587Z"
				fill="url(#paint0_linear_1045_10855)"
			/>
			<path
				d="M23 6.86607C23.7404 7.2336 24.4172 7.57006 25.0948 7.90563C25.7347 8.22256 26.3809 8.52795 27.0109 8.86263C27.1734 8.94874 27.3313 9.1041 27.4148 9.26656C28.0332 10.457 28.6318 11.6573 29.2376 12.8549C29.2915 12.9605 29.3507 13.0635 29.4306 13.21C29.5087 13.0732 29.5715 12.9747 29.6236 12.8717C30.2303 11.675 30.828 10.4739 31.4473 9.28431C31.5361 9.11387 31.7013 8.94697 31.8736 8.85908C33.0762 8.24564 34.2897 7.6544 35.4995 7.05517C35.6054 7.00279 35.7077 6.94242 35.8522 6.8643C35.7104 6.78618 35.6099 6.72492 35.5049 6.67254C34.2852 6.06887 33.0619 5.47141 31.8476 4.85709C31.6923 4.77896 31.5415 4.63071 31.4625 4.47802C30.7912 3.17835 30.1369 1.8698 29.4441 0.499993C29.3642 0.642034 29.3058 0.737021 29.2556 0.83645C28.6444 2.04291 28.0413 3.25381 27.4166 4.45316C27.3277 4.62361 27.1581 4.78518 26.9858 4.87307C25.793 5.48118 24.5895 6.06887 23.3904 6.66367C23.2818 6.71782 23.175 6.77552 23 6.86607Z"
				fill="#706DDA"
			/>
			<path
				d="M1 31.557C2.06533 32.0843 3.07771 32.577 4.08112 33.0893C4.22651 33.1629 4.36652 33.3032 4.44191 33.447C4.91579 34.3499 5.36993 35.2625 5.83124 36.1715C5.8806 36.2683 5.93445 36.3624 6.00805 36.5C6.52591 35.4791 7.01146 34.4963 7.52483 33.5287C7.62445 33.3414 7.80664 33.1603 7.99601 33.0617C8.97429 32.5539 9.96782 32.0737 11.0107 31.5561C10.8698 31.4815 10.7774 31.43 10.6822 31.383C9.79281 30.9418 8.90608 30.4961 8.01127 30.0656C7.77882 29.9537 7.62176 29.8081 7.50688 29.5729C7.02492 28.5892 6.52232 27.6163 6.00536 26.5962C5.87073 26.8581 5.75316 27.0818 5.63918 27.3073C5.25864 28.0584 4.87181 28.8067 4.50384 29.564C4.39165 29.7957 4.24177 29.951 4.00573 30.0638C3.11002 30.4926 2.22419 30.9391 1.33477 31.3803C1.23604 31.4292 1.14001 31.4824 1.0009 31.5552L1 31.557Z"
				fill="#B9B8FD"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1045_10855"
					x1={30.8764}
					y1={6.9246}
					x2={-3.19245}
					y2={11.5511}
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#FE55F4" />
					<stop offset={1} stopColor="#6DDBFE" />
				</linearGradient>
			</defs>
		</svg>
	),
	Download: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M2.08203 16.5942H17.9154"
				stroke="#5553BA"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M10.0003 3.39999L10.0003 12.1963M10.0003 12.1963L14.3984 8.9384M10.0003 12.1963L5.60214 8.9384"
				stroke="#5553BA"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Tick: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="20"
			viewBox="0 0 19 20"
			fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.946 1.14424C10.0147 0.358169 8.6523 0.35817 7.72098 1.14424L6.87282 1.86013C6.47698 2.19423 5.98715 2.39714 5.471 2.44079L4.36505 2.53432C3.15067 2.63702 2.18731 3.60038 2.08461 4.81475L1.99108 5.9207C1.94743 6.43685 1.74452 6.92669 1.41042 7.32254L0.694533 8.17069C-0.0915378 9.102 -0.0915366 10.4644 0.694533 11.3957L1.41042 12.2439C1.74452 12.6397 1.94743 13.1296 1.99108 13.6457L2.08461 14.7517C2.18731 15.9661 3.15067 16.9295 4.36505 17.0321L5.471 17.1256C5.98715 17.1693 6.47698 17.3722 6.87283 17.7063L7.72098 18.4222C8.6523 19.2082 10.0147 19.2082 10.946 18.4222L11.7942 17.7063C12.19 17.3722 12.6799 17.1693 13.196 17.1256L14.302 17.0321C15.5164 16.9295 16.4797 15.9661 16.5824 14.7517L16.6759 13.6457C16.7196 13.1296 16.9225 12.6397 17.2566 12.2439L17.9725 11.3957C18.7585 10.4644 18.7585 9.102 17.9725 8.17069L17.2566 7.32253C16.9225 6.92669 16.7196 6.43685 16.6759 5.9207L16.5824 4.81475C16.4797 3.60038 15.5164 2.63702 14.302 2.53432L13.196 2.44079C12.6799 2.39714 12.19 2.19423 11.7942 1.86013L10.946 1.14424ZM13.8795 8.07875C14.3189 7.63942 14.3189 6.9271 13.8795 6.48777C13.4402 6.04842 12.7279 6.04842 12.2885 6.48777L8.08403 10.6923L6.37953 8.98777C5.9402 8.54842 5.22788 8.54842 4.78855 8.98777C4.3492 9.4271 4.3492 10.1394 4.78855 10.5788L7.28855 13.0787C7.72788 13.5181 8.4402 13.5181 8.87953 13.0787L13.8795 8.07875Z"
				fill="#5271FF"
			/>
		</svg>
	),
	Res: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none">
			<path
				d="M7.75 8.08301H3.16667V3.49967"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.19606 7.32293C6.22681 3.28884 11.1433 1.6648 15.1774 3.69554C19.2115 5.72628 20.8356 10.6428 18.8048 14.6769C16.7741 18.711 11.8576 20.335 7.82346 18.3043C5.83268 17.3022 4.42883 15.5972 3.76148 13.6424"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Prev: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none">
			<path
				d="M16.5 8L1.5 8M1.5 8L7.33333 1.75M1.5 8L7.33333 14.25"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Next: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none">
			<path
				d="M1.5 8L16.5 8M16.5 8L10.6667 1.75M16.5 8L10.6667 14.25"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
};
