import { TParams, TResponse } from "./app";

export interface IModelBodyProps {
	setSelectedFile?: (file: File | null) => void;
	dataGallery?: TResponse<IGallery[]>;
	setParamsGallery: React.Dispatch<React.SetStateAction<TParams>>;
	paramsGallery: TParams;
	isLoadingGallery: boolean;
}

export interface IClothesProps {
	setSelectedFile?: (file: File | null) => void;
	valueCategory?: string;
	setValueCategory?: (value: string) => void;
	valueSize?: string;
	setValueSize?: (value: string) => void;
}

export interface IOutfitFinalsProps {
	result?: IFinalOutfitResult;
	isLoading?: boolean;
}

export interface IFinalOutfitResult {
	url?: string | null;
	status?: EnumStatusOutfit;
	totalStep?: number;
	currentStep?: number;
	message?: string | null;
	galleryId?: string | null;
}

export interface IOutfitParams {
	fileClothes?: string | null;
	fileModel?: string | null;
	modelBodyId?: string | null;
	galleryId?: string | null;
	clothesId?: string | null;
}

export interface IFaceSwapParams {
	fileModel?: string | null;
	fileFace?: string | null;
	modelBodyId?: string | null;
	galleryId?: string | null;
}

export interface IModelType {
	createdAt?: string; //"2024-07-25T07:33:51.608Z";
	id?: string; //"66a1ffdf2775f3ca7bd2a8a6";
	image?: string; //"https://storage.googleapis.com/illuminus-public/style-gen/model/Female-Retanglebodyshape-Underweight.png";
	isDeleted?: boolean; //false;
	name?: string; //"Retanglebodyshape1";
	typeModel?: string; //"FEMALE";
	updatedAt?: string; //"2024-07-25T07:33:51.608Z";
	_id?: string;
}

export enum EnumStatusOutfit {
	STARTED = "STARTED",
	PROCESSING = "PROCESSING",
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export interface IGallery {
	description?: string; //"";
	id?: string; //"66ab56ec0ca15f8221b20203";
	image?: string; //"http://localhost:8000/swap/outfit/result.png";
	isDeleted?: boolean; //false;
	maskBody?: string; //"";
	maskLength?: string; //"";
	serviceName?: string; //"OUTFIT";
	createdAt?: string;
	name: string;
}

export enum EDropType {
	BODY = "BODY",
	OUTFIT = "OUTFIT",
}
export interface IDetailGallery {
	clothesImage: string;
	createdAt: string;
	description: string;
	id: string;
	image: string;
	maskBody: string;
	maskLength: string;
	modelImage: string;
	type: string;
	uid: string;
	updatedAt: string;
	name: string;
}

export interface IModelParams {
	image?: string | null;
	name?: string | null;
	typeModel?: string | null;
}

export interface IDataRename {
	galleryId: string;
	name: string;
}
