import { ButtonCus, ModalSubmitInfo, TextCus } from "components";
import React from "react";
import { Box } from "@mui/material";
import { fontSize, mainColor } from "theme";
import { Icons } from "assets";
import { useNavigate } from "react-router-dom";
import { EPricingPlan, ResponsePlan } from "types/payment";
import { APP_ROOT } from "utils/routes";

const BoxPlan = ({
	isFree,
	item,
	typePlan,
	isEnterprise,
	isCurrentPlan,
	isDowngrade,
	showModel,
	setShowModel,
}: {
	isFree: boolean;
	item: ResponsePlan;
	typePlan: {
		value: number;
		name: EPricingPlan;
	};
	isEnterprise: boolean;
	isCurrentPlan: boolean;
	isDowngrade: boolean;
	showModel: boolean;
	setShowModel: (v: boolean) => void;
}) => {
	const navigate = useNavigate();
	const planText = () => {
		if (isFree) return "Get Started";
		if (isEnterprise) return "Let's Get in Touch!";
		if (isCurrentPlan) return "Current Plan";
		if (isDowngrade) return "Downgrade Plan";
		return "Upgrade Plan";
	};

	return (
		<Box
			maxWidth={"302px"}
			minWidth={"302px"}
			height={"347px"}
			bgcolor={mainColor.white}
			display={"flex"}
			flexDirection={"column"}
			padding={"15px"}
			sx={{ border: "1px solid #B2B2B2" }}
			justifyContent={"space-between"}
			borderRadius={"12px"}>
			<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					alignItems={"flex-start"}>
					<TextCus
						color={mainColor.textMain}
						fontSize={fontSize.md}
						fontWeight={"700"}>
						{item.packageName}
					</TextCus>
					<TextCus color={"#6F6C90"} fontWeight={"400"} fontSize={"12px"}>
						{item.subTitle}
					</TextCus>
				</Box>
				{isEnterprise ? (
					<TextCus
						color={mainColor.secondary}
						sx={{
							fontSize: {
								xs: "18px",
								xl: "30px",
							},
						}}
						fontWeight={"700"}>
						Custom Pricing
					</TextCus>
				) : (
					<Box display={"flex"} alignItems={"flex-end"}>
						<TextCus
							color={mainColor.secondary}
							sx={{
								fontSize: {
									xs: "18px",
									xl: "30px",
								},
							}}
							fontWeight={"700"}>
							{"$" + item.priceSale * (typePlan.value === 1 ? 1 : 0.85)}
						</TextCus>
						<TextCus color={"#6F6C90"} fontWeight={"600"} fontSize={"14px"}>
							/month
						</TextCus>
					</Box>
				)}

				<Box>
					<TextCus
						color={mainColor.secondary}
						sx={{
							fontSize: {
								xs: "14px",
							},
						}}
						fontWeight={"600"}>
						What’s included
					</TextCus>
					<Box
						component={"ul"}
						sx={{ listStyleType: "none" }}
						paddingLeft={"15px"}>
						{item.description.map((item, index) => {
							return (
								<Box
									component={"li"}
									display={"flex"}
									mt={"5px"}
									alignItems={"center"}
									gap={"15px"}
									color={"#002F74"}
									fontSize={"12px"}
									fontWeight={"400"}
									key={index}>
									{Icons.Tick()}
									{item}
								</Box>
							);
						})}
					</Box>
				</Box>
			</Box>
			<ButtonCus
				onClick={() => {
					if (isEnterprise) return setShowModel(true);
					navigate(
						isFree
							? APP_ROOT.STYLE_FIT
							: `${APP_ROOT.INFO_PAYMENT}?id=${item._id}&quantity=${typePlan.value}`,
					);
				}}
				sx={{
					mt: "15px",
					height: "41px",
					lineHeight: "40px",
					fontWeight: 600,
					fontSize: fontSize.sm,
				}}>
				{planText()}
			</ButtonCus>
			{isEnterprise && (
				<ModalSubmitInfo open={showModel} onClose={() => setShowModel(false)} />
			)}
		</Box>
	);
};

export default BoxPlan;
