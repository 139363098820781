/** @format */

import {
	do1,
	do2,
	eItem_1,
	eItem_2,
	Icons,
	not1,
	not2,
	not3,
	notItem_1,
	notItem_2,
	notItem_3,
} from "assets";
import { EPricingPlan } from "types/payment";
import { APP_ROOT } from "./routes";

export const drawerWidth = 240;

export const dimension = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export const KEY_CONTEXT = {
	AUTH_TOKEN: "authToken",
	REFRESH_TOKEN: "refreshToken",
	LANGUAGE: "language",
	THEME_MODE: "themeMode",
	USER: "user",
	SC_CONNECTION_TOKEN: "connectionToken",
	USER_ID: "userId",
	MODAL_REQUIRE: "modalRequire",
	DEFAULT_URL: "defaultUrl",
};

export const EmpLangSeclect = [
	{
		key: "EN",
		title: "English",
		value: "EN",
		useI18n: true,
	},
	{
		key: "VN",
		title: "Vietnamese",
		value: "VN",
		useI18n: true,
	},
];

export const defaultPagination = {
	total: 0,
	page: 0,
	pageSize: 25,
};

export enum EnumActionApproval {
	comment = "comment",
	approve = "approve",
	return = "return",
	reject = "reject",
	publish = "publish",
	archive = "archive",
	invite = "invite",
	delegate = "delegate",
	attach = "attach",
	print = "print",
	docversion = "docversion",
	history = "history",
	showmore = "showmore",
	lookup = "lookup",
}

export enum EnumBtnBottomModal {
	cancel = "cancel",
	approve = "approve",
	return = "return",
	reject = "reject",
	send = "send",
	save = "save",
	save_and_new = "save_and_new",
	ok = "ok",
	close = "close",
	/* Advance Search  */
	reset = "reset",
	save_as_profile = "save_as_profile",
	search = "search",
	downloadtemp = "downloadtemp",
	add = "add",
	update = "update",
	insert_update = "insert_update",
}

export enum EnumInputVariant {
	base = "base",
	browser = "browser",
	number = "number",
	select = "select",
}

export enum EnumValueDataType {
	String = "String",
	Number = "Number",
	Boolean = "Boolean",
	Object = "Object",
	Array = "Array",
	Null = "Null",
	Undefined = "Undefined",
	Function = "Function",
}

export enum EnumRangeApply {
	A = "A",
	S = "S",
}

export enum EnumYesNoBPMN {
	Y = "Yes",
	N = "No",
}

export enum EnumYesNo {
	Y = "Y",
	N = "N",
}

export enum EnumActionFilterBars {
	import = "import",
	export = "export",
	refresh = "refresh",
}

export const HEADER = {
	H_MOBILE: 64,
	H_DESKTOP: 80,
	H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
	WIDTH: 280,
};
export const EXPECT_LANG = ["ko", "en"];

export const QueryKeys = {
	PROFILE: "profile",
	MODEL_EXAMPLES: "models_examples",
	CLOTHES_EXAMPLES: "clothes_examples",
	MODELS: "models",
	GALLERY_LIST: "galleryList",
	GET_CLOTHES: "getClothes",
	GET_PLAN: "getPlan",
	GET_DETAIL_PLAN: "c",
	MODELS_RANDOM: "MODELS_RANDOM",
	YOUR_MODELS: "YOUR_MODELS",
	YOUR_CLOTHES: "YOUR_CLOTHES",
	GET_DETAIL_GALLERY: "GET_DETAIL_GALLERY",
	TRANSACTION: "TRANSACTION",
};

export const initialOptionsPaypal = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};

export const arrTab = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Use Model", value: "MODEL" },
];

export const arrTabItems = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Use Items", value: "ITEMS" },
];

export const arrGender = [
	{ name: "Male", value: "MALE" },
	{ name: "Female", value: "FEMALE" },
];

export const arrShould = [
	{ img: do1, title: "Front-facing" },
	{ img: do2, title: "Standing pose" },
];

export const arrNotShould = [
	{ img: not1, title: "Many persons" },
	{ img: not2, title: "Side pose" },
	{ img: not3, title: "Sitting pose" },
];

export const arrShouldItem = [
	{ img: eItem_1, title: "Clean background" },
	{ img: eItem_2, title: "Front angle shoot" },
];

export const arrNotShouldItem = [
	{ img: notItem_1, title: "Colorful background" },
	{ img: notItem_2, title: "Model wears items" },
	{ img: notItem_3, title: "Two items" },
];

export const CATEGORY = [
	{
		value: "UPPER",
		name: "Upper body",
	},
	{
		value: "LOWER",
		name: "Lower body",
	},
	{
		value: "DRESSES",
		name: "Dress body",
	},
];

export const SIZE_BODY = [
	{
		value: "full-length",
		name: "Full length",
	},
	{
		value: "knee-length",
		name: "Knee length",
	},
	{
		value: "mid-thigh",
		name: "Mid thigh",
	},
];

export const DATA_BUTTON = [
	{ value: 1, name: EPricingPlan.MONTHLY },
	{ value: 12, name: EPricingPlan.ANNUAL },
];

export const arrMenu = [
	{
		icon: Icons.Home(),
		text: "Home page",
		link: APP_ROOT.DASHBOARD,
	},
	{
		icon: Icons.User(),
		text: "Models",
		link: APP_ROOT.MODELS,
	},
	{
		icon: Icons.Star(),
		text: "Your Generations",
		link: APP_ROOT.HISTORY,
	},
	{
		icon: Icons.Api(),
		text: "Pricing",
		link: APP_ROOT.PLAN,
	},
	{
		icon: Icons.Transaction(),
		text: "Transactions",
		link: APP_ROOT.TRANSACTION,
	},
];
