import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TitleSection from "./TitleSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useModel, useOutfitStore, useUser, useWindowDimensions } from "hooks";
import { TParams } from "types";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { IModelType } from "types/outfit";

const YourModels = () => {
	const navigate = useNavigate();
	const { width } = useWindowDimensions();
	const { isLogged } = useUser();
	const { useGetModelRandom } = useModel();
	const [paramsModels, setParamsModels] = useState<TParams>({
		page: 1,
		limit: 6,
	});

	useEffect(() => {
		width < 996
			? setParamsModels({ ...paramsModels, limit: 5 })
			: setParamsModels({ ...paramsModels, limit: 6 });
	}, [width]);

	const { models: dataModel } = useGetModelRandom(paramsModels);
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileModel(item._id as string);
		setModelImage(item.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	return (
		<Box
			className="wrap-content"
			width={"100%"}
			mt={isLogged ? "70px" : "150px"}>
			<TitleSection
				title="Your Preset Models"
				action={() => navigate(APP_ROOT.MODELS)}
			/>
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				gap={"15px"}>
				{dataModel.map((item, index) => {
					return (
						<Box
							component={"div"}
							width={"133px"}
							height={"133px"}
							borderRadius={"100%"}
							border="2px solid #ccc"
							overflow={"hidden"}
							onClick={() => handleChooseModel(item)}
							display={"flex"}
							alignItems={"start"}
							justifyContent={"center"}
							sx={{
								transition: "all 0.35s ease-in-out",
								cursor: "pointer",
								":hover": {
									".img_model": {
										transform: "scale(1.075)",
									},
								},
							}}
							key={`${item?.id || "" + index}`}>
							<LazyLoadImage
								className="img_model"
								style={{
									display: "block",
									objectFit: "cover",
									width: "100%",
									transition: "all 0.35s ease-in-out",
								}}
								src={`${item?.image}?w=164&h=164&fit=crop&auto=format`}
								srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="model"
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default YourModels;
