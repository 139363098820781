/** @format */

export type TLogin = {
	email: string;
	password: string;
};

export type TLoginSocial = {
	idToken: string;
};

export type TRegister = {
	userName: string;
	email: string;
	password: string;
	repassword: string;
};

export interface TForgotPassword {
	email?: string;
	uid?: string;
	otp?: string;
	newPassword?: string;
	repassword?: string;
}

export interface IInputFX {
	type: string;
	name: string;
	placeholder?: string;
	required?: boolean;
	value: string;
	setValue?: (newValue: string) => void;
	formData?: TForgotPassword;
	setFormData?: (data: TForgotPassword) => void;
	errors?: TForgotPassword;
	setErrors?: (error: TForgotPassword) => void;
}

export interface IUser {
	avatar: string;
	credits: number;
	email: string;
	id: string;
	plan: string;
	totalBill: number;
	userName: string;
	startDate: string;
	typeLogin: TypeLogin.GOOGLE | TypeLogin.SYSTEM;
}

export interface IContextState {
	profile: any;
}

export enum IUserActionsType {
	UPDATE_PARAMS = "UPDATE_PARAMS",
}

export interface AuthContextType {
	isLogged: boolean;
	login: () => void;
	logout: () => void;
}

export enum EModelAuth {
	LOGIN = "login",
	REGISTER = "register",
	VERIFY = "verify",
	FORGOT = "forgot",
	RESET_PASS = "resetPass",
	DEFAULT = "",
}
export enum TypeSendMailOtp {
	REGISTER = "REGISTER",
	FOR_GOT_PASS_WORD = "FOR_GOT_PASS_WORD",
}

export interface UpdateProfileForm {
	userName: string;
	avatar?: File;
}

export enum TypeLogin {
	SYSTEM = "SYSTEM",
	GOOGLE = "GOOGLE",
}
