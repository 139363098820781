/** @format */

import { EModelAuth } from "types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	openModalGlobal: boolean;
	loadingGlobal: boolean;
	openModalCreateCharacter: boolean;
	openModalAuth: EModelAuth;
	openModalExpireSession: boolean;
};

type Actions = {
	setOpenModalGlobal: (v: boolean) => void;
	setLoadingGlobal: (v: boolean) => void;
	setOpenModalCreateCharacter: (v: boolean) => void;
	setOpenModalAuth: (v: EModelAuth) => void;
	setOpenModalExpireSession: (v: boolean) => void;
};

const initialState: State = {
	openModalGlobal: false,
	loadingGlobal: false,
	openModalCreateCharacter: false,
	openModalAuth: EModelAuth.DEFAULT,
	openModalExpireSession: false,
};

export const useLayout = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setOpenModalGlobal: (v) => set({ openModalGlobal: v }),
			setLoadingGlobal: (v) => set({ loadingGlobal: v }),
			setOpenModalCreateCharacter: (v) => set({ openModalCreateCharacter: v }),
			setOpenModalAuth: (v) => set({ openModalAuth: v }),
			setOpenModalExpireSession: (v) => set({ openModalExpireSession: v }),
		}),
		{
			name: "LayoutStore",
		},
	),
);
