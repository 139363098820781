import { Box, CircularProgress } from "@mui/material";
import { TextCus } from "components/TextCus";
import { useOutfitStore } from "hooks";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { mainColor } from "theme";
import { APP_ROOT } from "utils/routes";

const ProcessGlobal = () => {
	const navigate = useNavigate();
	const { isLoadingProcess } = useOutfitStore();
	const location = useLocation();

	if (isLoadingProcess && location.pathname !== APP_ROOT.STYLE_FIT) {
		return (
			<Box
				component={"button"}
				border={"none"}
				onClick={() => navigate(APP_ROOT.STYLE_FIT)}
				position={"absolute"}
				zIndex={999}
				sx={{ background: mainColor.bgLinear, cursor: "pointer" }}
				top={"80px"}
				display={"flex"}
				gap={"10px"}
				alignItems={"center"}
				padding={"10px"}
				borderRadius={"12px"}
				right={"15px"}>
				<TextCus color={"white"} fontWeight={"700"}>
					You have a running process
				</TextCus>
				<CircularProgress
					sx={{
						color: "white",
						width: "20px !important",
						height: "20px !important",
					}}
				/>
			</Box>
		);
	} else {
		return <></>;
	}
};

export default ProcessGlobal;
