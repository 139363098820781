/** @format */

import React, { FC, useEffect } from "react";
import { LoadingPage, ModalCheckMobile, ProcessGlobal } from "components";
import {
	useDetectMultiTab,
	useDevice,
	useLoginOneDevice,
	useUser,
	useWindowDimensions,
} from "hooks";
import i18next from "i18next";
import { MainLayout } from "components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { privateRoutes, RouterType } from "./routes";
import NotFound from "pages/404/Notfound";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
	return <>{children}</>;
};

function App() {
	const { deviceId } = useDevice();
	const { languages } = useUser();
	const { width } = useWindowDimensions();

	useDetectMultiTab();
	useLoginOneDevice(deviceId);

	useEffect(() => {
		if (languages.value) {
			i18next.changeLanguage(languages.value);
		}
	}, [languages.value]);

	return (
		<Router basename="/">
			<React.Suspense fallback={<LoadingPage />}>
				<ProcessGlobal />
				{width < 768 && <ModalCheckMobile />}
				<Routes>
					{privateRoutes.map((route: RouterType, index) => {
						const Layout = route.layout || MainLayout;
						const Component = route.component;
						return (
							<Route
								key={`${index}-${route.path}`}
								path={route.path}
								element={
									<RequireAuth>
										<Layout>
											<Component />
										</Layout>
									</RequireAuth>
								}
							/>
						);
					})}
					<Route path={"*"} element={<NotFound />} />
				</Routes>
			</React.Suspense>
		</Router>
	);
}

export default App;
