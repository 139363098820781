/** @format */

import React from "react";
import { Icons } from "assets";
import {
	Box,
	FormControl,
	MenuItem,
	Tooltip,
	tooltipClasses,
	TooltipProps,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";
import { TextCus } from "components/TextCus";
import { mainColor } from "theme";

interface CommonSelectProps {
	options: { value: string; name: string }[];
	value: string;
	onChange: (event: SelectChangeEvent<any>) => void;
	width?: string;
	label?: string;
	titleTooltip?: string;
}

const CustomSelect = styled(Select)(() => ({
	backgroundColor: "#F5F5F5",
	borderRadius: "10px",
	height: "35px",
	"& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
		"::before": {
			content: "none",
		},
		"::after": {
			content: "none",
		},
	},
	"& .MuiFormLabel-root.MuiInputLabel-root": {
		display: "none !important",
	},
	"& .MuiSelect-icon": {
		color: "#3F51B5",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	padding: "10px 16px",
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#ccc",
		color: mainColor.secondary,
		boxShadow: "white",
		fontSize: 11,
		maxWidth: "150px",
	},
}));

const SelectCus = ({
	options,
	value,
	onChange,
	width,
	label,
	titleTooltip,
	...selectProps
}: CommonSelectProps) => {
	return (
		<LightTooltip title={titleTooltip} placement="top">
			<FormControl fullWidth variant="outlined">
				{label && (
					<Box display={"flex"} alignItems={"start"} mb={"5px"}>
						<TextCus fontSize={"13px"} fontWeight={"400"}>
							{label}
						</TextCus>
						<TextCus color={"red"} ml={"2px"}>
							*
						</TextCus>
					</Box>
				)}
				<CustomSelect
					sx={{ width: width ?? "100%" }}
					IconComponent={() => (
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Icons.ArrowSelect />
						</Box>
					)}
					value={value}
					variant="standard"
					onChange={onChange}
					{...selectProps}>
					{options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.name}
						</MenuItem>
					))}
				</CustomSelect>
			</FormControl>
		</LightTooltip>
	);
};

export default SelectCus;
